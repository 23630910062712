/**
 * @file A bridge for communication with electron app
 * */
import window from '@@/bits/global'

function fetchElectronBridge(): any {
  return window.electron || {}
}

// On the desktop app, we currently are opening an internal url in a window,
// when the app got redirected, we can use this type to determine the behavior of the app
// `internal` implies that it will keep the URL internally
// `external` implies that it will open the URL in the browser
enum URLTarget {
  Internal = 'internal',
  External = 'external',
}

export { URLTarget }
export default fetchElectronBridge
